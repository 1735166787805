/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import axios from 'axios'
import findIndex from 'lodash/findIndex'
import React, { useState, useEffect } from 'react'

import utils from 'components/react_components/utils'

export default function TermsOfUseAvailabilityCheck({ userIsStaff }: { userIsStaff: boolean }) {
    const [testHasRun, setTestHasRun] = useState(false)
    const [termsOfUse, setTermsOfUse] = useState(null)

    const fetchTerms = async () => {
        try {
            const terms = await axios.get(window.Urls['termsofuse:terms_list']())
            setTermsOfUse(terms)
        } catch (e) {
            utils.defaultAjaxErrorHandler(e)()
        }
    }
    useEffect(() => {
        if (userIsStaff) {
            fetchTerms()
        }
    }, [userIsStaff])

    const onHide = () => {
        setTestHasRun(true)
    }

    const checkTerms = () => {
        let errorText = ''
        if (termsOfUse) {
            const missingLanguages = []
            if (findIndex<any>(termsOfUse.data, t => t.language === 'en') === -1) {
                missingLanguages.push('en')
            }

            if (findIndex<any>(termsOfUse.data, t => t.language === 'de') === -1) {
                missingLanguages.push('de')
            }
            if (missingLanguages.length === 1) {
                errorText = interpolate(gettext_noop('Terms missing for language %s.'), missingLanguages)
            }
            if (missingLanguages.length === 2) {
                errorText = interpolate(gettext_noop('Terms missing for languages %s and %s.'), missingLanguages)
            }
        }
        return errorText
    }

    const errorMessage = checkTerms()
    if (!userIsStaff || testHasRun || !errorMessage) {
        return null
    }
    return (
        <Dialog open onClose={onHide}>
            <DialogTitle>{gettext_noop('Terms of Use missing')}</DialogTitle>
            <DialogContent>{errorMessage}</DialogContent>
            <DialogActions>
                <Button onClick={onHide} data-testid="okbutton" variant="text">
                    {gettext('OK')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
